import './App.css';
import React, { useState,useEffect  } from "react";
import Countdown from './components/home/countdown/Countdown';
import MeanTime from './components/home/countdown/MeanTime';
import Home from './home/Home';

export default function App(props) {
  return (
    <Home />
  )
}