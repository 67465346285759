import React, { Component } from 'react'
import './MeanTime.css'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { instagram } from '@fortawesome/free-solid-svg-icons'


export default class MeanTime extends Component {
  render() {
    return (
        <section className="meantime-container">
            <FontAwesomeIcon icon="fa-brands fa-instagram" />
        </section>
    )
  }
}
