import './Home.css';
import React, { useState,useEffect  } from "react";
import Countdown from '../components/home/countdown/Countdown';
import MeanTime from '../components/home/countdown/MeanTime';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom'
import Snowfall from 'react-snowfall'

function Home() {
const [timerDays, setTimerDays] = useState();
const [timerHours, setTimerHours] = useState();
const [timerMinutes, setTimerMinutes] = useState();
const [timerSeconds, setTimerSeconds] = useState();

setInterval(function(){ 
    let workingTs = (new Date().getTime() / 1000);
    console.log(workingTs);

    let TransferTime = 1703404800 + 1;

    if(workingTs >= TransferTime) {
        window.location.replace("https://tracker.santatracker.live")
    }

}, 1000);

let interval;

const startTimer=()=>{
  let UnixTimestamp = '1703404800'
  const countDownDate= new Date('1703404800' * 1000).getTime();
  
  interval=setInterval(()=> {
    const now = new Date().getTime();

    const distance = countDownDate - now;
    
    const days= Math.floor(distance/(24*60*60*1000)
    );
    const hours= Math.floor(distance%(24*60*60*1000)/(1000*60*60)
    );
    const minutes= Math.floor(distance%(60*60*1000)/(1000*60)
    );
    const seconds= Math.floor(distance%(60*1000)/(1000)
    );

    if(distance<0){
      // stop timer

      clearInterval(interval.current);
    }else{
      //update timer
      setTimerDays(days);
      setTimerHours(hours);
      setTimerMinutes(minutes);
      setTimerSeconds(seconds);
    }
  })
}

useEffect(()=>{
  startTimer();
})

  return (
    <div className="App">
        <section className="main-container">
            <h4>Welcome to</h4>
            <h1>santatracker.live!</h1>
        </section>
            <Countdown timerDays={timerDays}
            timerHours={timerHours}
            timerMinutes={timerMinutes}
            timerSeconds={timerSeconds}/>
            {/* <h1>Automatically tranferring to tracker. Please wait.</h1> */}
        <MeanTime />

        <Snowfall
          // Changes the snowflake color
          color="white"
          // Applied to the canvas element
          // Controls the number of snowflakes that are created (default 150)
          snowflakeCount={300}
        />
        
    </div>
  );
}

export default Home;
